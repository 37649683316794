import { useConfig } from '../../hooks/useConfig';
import React from 'react';

export const EmailLink = ({ className }) => {
  const {
    email: { address }
  } = useConfig();
  return (
    <a href={`mailto:${address}`} className={className}>
      {address}
    </a>
  );
};
