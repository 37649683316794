import { useStaticQuery, graphql } from 'gatsby';

export function useConfig() {
  const { configYaml } = useStaticQuery(
    graphql`
      query {
        configYaml(fields: { file: { regex: "//config.yml$/" } }) {
          headerTitle
          blogHeaderTitle
          blogHeaderText
          mainSEO {
            title
            description
            keywords
            canonicalUrl
          }
          blogSEO {
            title
            description
            keywords
          }
          socialUrl
          buttonCaption
          phone
          email {
            address
          }
        }
      }
    `
  );
  configYaml.phoneLink = 'tel:' + configYaml.phone.replace(/[^\d+]/g, '');
  return configYaml;
}
