import React from 'react';
import { createUseStyles } from 'react-jss';
import Modal from 'react-bootstrap/Modal';
import { Alert } from 'react-bootstrap';
import { CheckCircleFill } from 'react-bootstrap-icons';

const useStyles = createUseStyles((theme) => ({
  modal: {
    '& .modal-header': {
      paddingBottom: 0,
      border: 'none'
    }
  },
  modalText: {
    fontSize: 'var(--font-size-lg)',
    marginLeft: 10,
    verticalAlign: 'middle'
  },
  bullet: {
    fontSize: 'var(--font-size-xl)',
    display: 'inline-block'
  }
}));

export function Popup({ variant, content, ...other }) {
  const classes = useStyles();
  return (
    <Modal
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={classes.modal}
      {...other}
    >
      <Modal.Body>
        <Alert variant={variant}>
          <CheckCircleFill className={classes.bullet} />
          <span className={classes.modalText}>{content}</span>
        </Alert>
      </Modal.Body>
    </Modal>
  );
}
