import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { Envelope, PhoneFill } from 'react-bootstrap-icons';
import classNames from 'classnames';
import { Popup } from '../utility/popup';
import isEmail from 'validator/lib/isEmail';
import { useYellowButton } from '../../hooks/useYellowButton';
import Button from 'react-bootstrap/Button';
import { PhoneLink } from '../utility/phoneLink';
import { EmailLink } from '../utility/emailLink';

const useStyles = createUseStyles((theme) => ({
  footer: {
    paddingTop: 'var(--size-6x)',
    paddingBottom: 'var(--size-6x)',
    ...theme.minSidePadding,
    width: '100%',
    color: 'white',
    background: theme.black,
    position: 'relative'
  },
  container: {
    maxWidth: 'var(--layout-width)',
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    gap: '3vw'
  },
  title: {
    marginBottom: 'var(--font-size-xxl)',
    fontSize: 'var(--font-size-xl)'
  },
  contactDetails: {
    flex: '1 0 0'
  },
  contactForm: {},
  contact: {},
  contactText: {
    fontSize: 'var(--font-size-lg)'
  },
  icon: {
    display: 'inline',
    color: theme.yellow,
    marginRight: 8,
    width: 'var(--font-size-xl)',
    height: 'var(--font-size-xl)'
  },
  form: {
    flex: '1 0 0',
    display: 'grid',
    gridTemplateColumns: [['[name]', '1fr', '[email]', '1fr']],
    gridTemplateRows: [
      [
        '[details]',
        'auto',
        '[detail-errors]',
        'var(--font-size-xl)',
        '[text-area]',
        'auto',
        'var(--font-size-xl)',
        '[button]',
        'auto'
      ]
    ],
    columnGap: 'var(--font-size-xl)'
  },
  textarea: {
    gridRow: 'text-area',
    gridColumn: 'span 2'
  },
  formError: {
    marginTop: 0,
    marginLeft: 'var(--font-size-lg)',
    fontSize: 'var(--font-size-md)',
    color: '#ff5736',
    gridRow: 'detail-errors'
  },
  nameError: {
    gridColumn: 'name'
  },
  emailError: {
    gridColumn: 'email'
  },
  control: {
    fontSize: 'var(--font-size-xxl)'
  },
  name: {
    gridRow: 'details'
  },
  email: {
    gridRow: 'details'
  },
  button: {
    gridRow: 'button'
  },
  [`@media (max-width: ${theme.breakPoints.smMax})`]: {
    container: {
      flexDirection: 'column'
    },
    footer: {
      paddingTop: 'var(--size-4x)',
      paddingBottom: 'var(--size-4x)'
    }
  }
}));

export function Footer({ className }) {
  const [modal, setModal] = useState('none');
  const classes = useStyles();
  const buttonClass = useYellowButton();
  return (
    <>
      <footer className={classNames(classes.footer, className)} id="footer">
        <div className={classNames(classes.container)}>
          <div className={classes.contactDetails}>
            <header className={classes.title}>
              <p>
                Potrzebujesz umówić się symulację rozmowy kwalifikacyjnej po
                angielsku? Musisz szybko przetłumaczyć/sprawdzić dokumenty
                rekrutacyjne po angielsku? Potrzebujesz innej pomocy w zakresie
                angielskiego? Napisz lub zadzwoń do nas.
              </p>
              <p>
                Krótki czas oczekiwania - dostępne terminy nawet tego samego
                dnia. Możliwość zapisu między 8.00-21.00, także w weekendy i
                święta.
              </p>
            </header>
            <div className={classes.contact}>
              <PhoneFill className={classes.icon} />
              <PhoneLink className={classes.contactText} />
            </div>
            <div className={classes.contact}>
              <Envelope className={classes.icon} />
              <EmailLink className={classes.contactText} />
            </div>
          </div>
          <Formik
            initialValues={{ email: '', name: '', message: '' }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = 'Wymagane';
              } else if (!isEmail(values.email)) {
                errors.email = 'Nieprawidłowy adres email';
              }
              if (!values.name) {
                errors.name = 'Wymagane';
              }
              if (!values.message) {
                errors.message = 'Wiadomość jest pusta';
              }
              return errors;
            }}
            onSubmit={(
              { email, name, message },
              { setSubmitting, resetForm }
            ) => {
              fetch(
                `.netlify/functions/contact?email=${encodeURIComponent(
                  email
                )}&name=${encodeURIComponent(name)}`,
                {
                  method: 'POST',
                  body: JSON.stringify({ text: message })
                }
              )
                .then((r) => {
                  if (!r.ok) {
                    throw Error(r.statusText);
                  }
                  return r;
                })
                .then((r) => {
                  setModal('success');
                  setSubmitting(false);
                  resetForm();
                })
                .catch((r) => {
                  setModal('failure');
                  setSubmitting(false);
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => {
              return (
                <Form
                  noValidate
                  onSubmit={handleSubmit}
                  className={classes.form}
                >
                  <Form.Control
                    id="footer-name-input"
                    type="text"
                    name="name"
                    placeholder="Imię"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    isValid={touched.name && !errors.name}
                    isInvalid={touched.name && errors.name}
                    className={classNames(classes.control, classes.name)}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className={classNames(classes.formError, classes.nameError)}
                  >
                    {errors.name}
                  </Form.Control.Feedback>
                  <Form.Control
                    type="text"
                    name="email"
                    placeholder="Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    isValid={touched.email && !errors.email}
                    isInvalid={touched.email && errors.email}
                    className={classNames(classes.control, classes.email)}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className={classNames(
                      classes.formError,
                      classes.emailError
                    )}
                  >
                    {errors.email}
                  </Form.Control.Feedback>
                  <Form.Control
                    as="textarea"
                    rows={6}
                    name="message"
                    placeholder="Wiadomość"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.message}
                    isValid={touched.message && !errors.message}
                    isInvalid={touched.message && errors.message}
                    className={classNames(classes.textarea, classes.control)}
                  />
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    className={classNames(
                      classes.control,
                      classes.button,
                      buttonClass
                    )}
                  >
                    Wyślij
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </div>
      </footer>
      <Popup
        variant="success"
        content="Wiadomość została wysłana"
        key="success"
        show={modal === 'success'}
        onHide={() => setModal('none')}
      />
      <Popup
        variant="danger"
        content="Wysłanie wiadomości nie powiodło się. Spróbuj ponownie później."
        key="failure"
        show={modal === 'failure'}
        onHide={() => setModal('none')}
      />
    </>
  );
}
