import React from 'react';
import { createUseStyles } from 'react-jss';
import { Contact } from './contact';
import { PhoneLink } from '../utility/phoneLink';
import { EmailLink } from '../utility/emailLink';

const useStyles = createUseStyles((theme) => ({
  contacts: {
    position: 'absolute',
    right: 0,
    bottom: 'calc(var(--font-size-xl) * -0.5 - var(--font-size-xs) * 0.7)',
    display: 'flex',
    justifyContent: 'flex-end',
    zIndex: 5
  },
  [`@media (max-width: ${theme.breakPoints.xsMax})`]: {
    contacts: {
      flexDirection: 'column',
      alignItems: 'flex-end',
      bottom: 'calc(var(--font-size-md) * -5)'
    }
  }
}));

export function Contacts() {
  const classes = useStyles();
  return (
    <div className={classes.contacts}>
      <Contact>
        <PhoneLink />
      </Contact>
      <Contact>
        <EmailLink />
      </Contact>
    </div>
  );
}
