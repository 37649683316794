import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme) => ({
  button: {
    backgroundColor: theme.yellow,
    borderColor: theme.yellow,
    color: theme.black,
    fontWeight: 600,
    fontSize: 'var(--font-size-md)',
    '&:hover': {
      backgroundColor: theme.yellow,
      borderColor: theme.yellow,
      color: theme.black
    }
  }
}));

export function useYellowButton() {
  const classes = useStyles();
  return classes.button;
}
