import React from 'react';
import { Menu } from './menu';
import { createUseStyles } from 'react-jss';
import { Footer } from './footer';
import '../../styles/global.css';
import '../../styles/bootstrap.scss';
import classNames from 'classnames';

const useStyles = createUseStyles((theme) => ({
  layout: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '--layout-width': (props) => `${props.maxWidth}px`
  },
  container: {
    flex: '1 0 0'
  },
  contentContainer: (props) =>
    props.limitContentWidth ? { ...theme.minSidePadding } : {},
  content: (props) => ({
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: props.limitContentWidth ? 'var(--layout-width)' : null,
    '& p > img': {
      display: 'block',
      margin: 'auto',
      maxWidth: 800,
      width: '100%'
    }
  }),
  [`@media (max-width: ${theme.breakPoints.xsMax})`]: {
    layout: {
      '--min-side-padding': 'var(--font-size-xs)'
    }
  }
}));

export function Layout({ children, header, slug, contentClass, ...other }) {
  const classes = useStyles(other);
  return (
    <div className={classes.layout}>
      <div className={classes.container}>
        <Menu activeKey={slug} />
        {header}
        <div className={classes.contentContainer}>
          <div className={classNames(classes.content, contentClass)}>
            {children}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
