import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme) => ({
  contact: {
    background: '#242424',
    color: 'white',
    fontSize: 'var(--font-size-md)',
    padding: [['calc(var(--font-size-xs) * 0.7)', 'var(--font-size-md)']],
    borderRadius: '1rem',
    zIndex: 5
  },
  [`@media (max-width: ${theme.breakPoints.xsMax})`]: {
    contact: {
      opacity: 0.7
    }
  }
}));

export function Contact(props) {
  const classes = useStyles();
  return <div className={classes.contact}>{props.children}</div>;
}
