import React, { useEffect, useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Nav from 'react-bootstrap/Nav';
import NavBar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { prependSlashIfAbsent } from '../../utils/url';
import Logo from '../../assets/logo.svg';
import { Contacts } from './contacts';
import { createUseStyles } from 'react-jss';
import { Cart3 } from 'react-bootstrap-icons';
import Container from 'react-bootstrap/Container';
import classNames from 'classnames';

/*global Snipcart */

const useStyles = createUseStyles((theme) => ({
  menu: {
    fontSize: 'var(--font-size-lg)',
    fontWeight: 700,
    paddingTop: 0,
    paddingBottom: 0,
    ...theme.minSidePadding
  },
  container: {
    maxWidth: 'var(--layout-width)',
    position: 'relative',
    padding: [['1rem', 0]]
  },
  cartContainer: {
    position: 'relative',
    marginLeft: 'var(--font-size-lg)'
  },
  cart: {
    fontSize: 'var(--font-size-xxl)',
    cursor: 'pointer'
  },
  cartItems: {
    textAlign: 'center',
    lineHeight: 1,
    width: 'var(--font-size-lg)',
    height: 'var(--font-size-lg)',
    borderRadius: '50%',
    background: theme.yellow,
    fontSize: 'var(--font-size-md)',
    position: 'absolute',
    bottom: 'calc(-0.5 * var(--font-size-lg))',
    right: 'calc(-0.5 * var(--font-size-lg))',
    cursor: 'pointer',
    zIndex: 2
  },
  itemsNumber: {
    display: 'inline-block',
    verticalAlign: 'middle'
  }
}));

const CART_CHANGING_EVENTS = [
  'snipcart.initialized',
  'cart.created',
  'cart.reset',
  'item.added',
  'item.updated',
  'item.removed'
];

export function Menu({ activeKey }) {
  const data = useStaticQuery(graphql`
    query {
      configYaml(fields: { file: { regex: "//menu.yml$/" } }) {
        fields {
          parsedMenu {
            label
            slug
            items {
              label
              slug
            }
          }
        }
      }
    }
  `);
  const [cartItems, setCartItems] = useState(0);

  const updateCartItems = () => {
    const items = Snipcart.store.getState().cart.items.count;
    setCartItems(items);
  };
  const classes = useStyles();
  useEffect(() => {
    const unsubscriptions = CART_CHANGING_EVENTS.map((e) =>
      Snipcart.events.on(e, updateCartItems)
    );
    return function () {
      unsubscriptions.forEach((u) => u());
    };
  }, []);
  return (
    <NavBar bg="white" expand="lg" className={classes.menu}>
      <Container className={classNames(classes.container)}>
        <NavBar.Toggle />
        <NavBar.Brand href={'/'}>
          <Logo />
        </NavBar.Brand>
        <NavBar.Collapse className="justify-content-end" expand="sm">
          {data.configYaml.fields.parsedMenu.map(processNode)}
          <div
            className={classes.cartContainer}
            onClick={(e) => {
              e.nativeEvent.stopImmediatePropagation();
              Snipcart.api.theme.cart.open();
            }}
          >
            <Cart3 className={classes.cart} />
            {cartItems !== 0 && (
              <div className={classes.cartItems}>
                <span className={classes.itemsNumber}>{cartItems}</span>
              </div>
            )}
          </div>
        </NavBar.Collapse>
        {/* TODO Wydzielić do osobnego komponentu*/}
        <Contacts />
      </Container>
    </NavBar>
  );
}

const simpleOption = (node) => (
  <Nav.Item key={node.slug}>
    <Nav.Link
      to={prependSlashIfAbsent(node.slug)}
      as={Link}
      eventKey={node.slug}
    >
      {node.label}
    </Nav.Link>
  </Nav.Item>
);

const submenu = (node) => (
  <NavDropdown id={node.label} title={node.label} key={node.label}>
    {node.items.map(dropdownOption)}
  </NavDropdown>
);

const dropdownOption = (node) => (
  <NavDropdown.Item
    as={Link}
    to={prependSlashIfAbsent(node.slug)}
    key={node.slug}
  >
    {node.label}
  </NavDropdown.Item>
);

const processNode = (node) => (node.slug ? simpleOption(node) : submenu(node));
